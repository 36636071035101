

















import { defineComponent, computed } from '@vue/composition-api';
import { ArrowRightSmallIcon, ScanQrCodeIcon } from '@nimiq/vue-components';
import { useConfig } from '@/composables/useConfig';
import { AddressType, useAddressStore } from '../stores/Address';
import { useAccountStore } from '../stores/Account';
import { CryptoCurrency } from '../lib/Constants';
import { useBtcAddressStore } from '../stores/BtcAddress';
import { useWindowSize } from '../composables/useWindowSize';
import { ColumnType, useActiveMobileColumn } from '../composables/useActiveMobileColumn';
import { usePolygonAddressStore } from '../stores/PolygonAddress';
import { useAccountSettingsStore } from '../stores/AccountSettings';

export default defineComponent({
    setup(props, context) {
        const { activeAddressInfo, addressInfos } = useAddressStore();
        const { activeCurrency, activeAccountInfo, hasBitcoinAddresses } = useAccountStore();
        const { stablecoin } = useAccountSettingsStore();
        const { accountBalance: btcBalance } = useBtcAddressStore();
        const { accountUsdcBalance, accountUsdtBridgedBalance } = usePolygonAddressStore();
        const { isMobile } = useWindowSize();
        const { activeMobileColumn } = useActiveMobileColumn();

        function nimOrBtcOrStable<T>(nim: T, btc: T, stable: T): T {
            switch (activeCurrency.value) {
                case CryptoCurrency.NIM: return nim;
                case CryptoCurrency.BTC: return btc;
                case CryptoCurrency.USDC:
                case CryptoCurrency.USDT:
                    return stable;
                default: throw new Error('Invalid active currency');
            }
        }

        const hasMultipleReceivableAddresses = computed(() => (
            addressInfos.value.filter(({ type }) => type === AddressType.BASIC).length > 1));

        function receive() {
            if (isMobile.value
                && activeMobileColumn.value !== ColumnType.ADDRESS
                && (hasMultipleReceivableAddresses.value || hasBitcoinAddresses.value)
            ) {
                // redirect to the address selector
                context.root.$router.push('/receive');
            } else {
                context.root.$router.push(nimOrBtcOrStable('/receive/nim', '/receive/btc', '/receive/usdc'));
            }
        }

        const hasMultipleSendableAddresses = computed(() =>
            activeAccountInfo.value && activeAccountInfo.value.addresses.length > 1);

        function send() {
            if (isMobile.value
                && activeMobileColumn.value !== ColumnType.ADDRESS
                && (hasMultipleSendableAddresses.value || hasBitcoinAddresses.value)
            ) {
                // redirect to the address selector
                context.root.$router.push('/send');
            } else {
                context.root.$router.push(nimOrBtcOrStable('/send/nim', '/send/btc', '/send/usdc'));
            }
        }

        const { config } = useConfig();

        const sendDisabled = computed(() => {
            if (config.disableNetworkInteraction && activeCurrency.value === CryptoCurrency.NIM) return true;
            return context.root.$route.path !== '/' && nimOrBtcOrStable(
                !activeAddressInfo.value || !activeAddressInfo.value.balance,
                !btcBalance.value,
                (stablecoin.value === CryptoCurrency.USDC
                    ? !accountUsdcBalance.value
                    : stablecoin.value === CryptoCurrency.USDT
                        ? !accountUsdtBridgedBalance.value
                        : true),
            );
        });

        return {
            receive,
            send,
            sendDisabled,
        };
    },
    components: {
        ArrowRightSmallIcon,
        ScanQrCodeIcon,
    },
});
