










































































































































































































































































































































import { defineComponent, computed, ref, watch, onMounted, onUnmounted, onActivated } from '@vue/composition-api';
import { SwapAsset } from '@nimiq/fastspot-api';
import { ArrowRightSmallIcon, AlertTriangleIcon, CircleSpinner, Tooltip } from '@nimiq/vue-components';
import AccountBalance from '../AccountBalance.vue';
import AddressList from '../AddressList.vue';
import BitcoinIcon from '../icons/BitcoinIcon.vue';
import UsdcIcon from '../icons/UsdcIcon.vue';
import UsdtIcon from '../icons/UsdtIcon.vue';
import UsdtIconPadded from '../icons/UsdtIconPadded.vue';
import MenuIcon from '../icons/MenuIcon.vue';
import Amount from '../Amount.vue';
import FiatConvertedAmount from '../FiatConvertedAmount.vue';
import ConsensusIcon from '../ConsensusIcon.vue';
import MobileActionBar from '../MobileActionBar.vue';
import LegacyAccountNotice from '../LegacyAccountNotice.vue';
import LegacyAccountUpgradeButton from '../LegacyAccountUpgradeButton.vue';
import LegacyAccountNoticeModal from '../modals/LegacyAccountNoticeModal.vue';
// import OasisLaunchModal from '../swap/OasisLaunchModal.vue';
import AttentionDot from '../AttentionDot.vue';
// import StakingSummaryMobile from '../staking/StakingSummaryMobile.vue';
import { backup, addAddress } from '../../hub';
import { useAccountStore, AccountType } from '../../stores/Account';
import { useBtcAddressStore } from '../../stores/BtcAddress';
import { usePolygonAddressStore } from '../../stores/PolygonAddress';
import { useWindowSize } from '../../composables/useWindowSize';
import { CryptoCurrency } from '../../lib/Constants';
import { useBtcNetworkStore } from '../../stores/BtcNetwork';
import { useSettingsStore } from '../../stores/Settings';
import { usePolygonNetworkStore } from '../../stores/PolygonNetwork';
import MiniAddIcon from '../icons/MiniAddIcon.vue';
import DoubleArrowIcon from '../icons/DoubleArrowIcon.vue';
import LinkedDoubleArrowIcon from '../icons/LinkedDoubleArrowIcon.vue';
import AddressListBackgroundSvg from '../AddressListBackgroundSvg.vue';
import { useAddressStore } from '../../stores/Address';
import { useConfig } from '../../composables/useConfig';
import router from '../../router';
import { useAccountSettingsStore } from '../../stores/AccountSettings';
// import { useStakingStore } from '../../stores/Staking';
// import AccountStake from '../staking/AccountStake.vue';

export default defineComponent({
    name: 'account-overview',
    setup(props, context) {
        const {
            activeAccountInfo,
            activeAccountId,
            setActiveCurrency,
            activeCurrency,
            hasBitcoinAddresses,
            hasPolygonAddresses,
        } = useAccountStore();
        const { accountBalance: nimAccountBalance } = useAddressStore();
        const { accountBalance: btcAccountBalance } = useBtcAddressStore();
        const {
            accountUsdcBridgedBalance,
            accountUsdcBalance,
            accountUsdtBridgedBalance,
        } = usePolygonAddressStore();
        const { stablecoin, knowsAboutUsdt } = useAccountSettingsStore();
        const { config } = useConfig();

        const isLegacyAccount = computed(() => (activeAccountInfo.value || false)
            && activeAccountInfo.value.type === AccountType.LEGACY);

        const canHaveMultipleAddresses = computed(() => (activeAccountInfo.value || false)
            && activeAccountInfo.value.type !== AccountType.LEGACY);

        const { isMobile, isTablet } = useWindowSize();

        function onAddressSelected() {
            setActiveCurrency(CryptoCurrency.NIM);

            if (isMobile.value) {
                context.root.$router.push('/transactions');
            }
        }

        function selectBitcoin() {
            if (!hasBitcoinAddresses.value) return;

            setActiveCurrency(CryptoCurrency.BTC);

            if (isMobile.value) {
                context.root.$router.push('/transactions');
            }
        }

        function selectStablecoin() {
            if (!hasPolygonAddresses.value || !stablecoin.value) return;

            if (stablecoin.value === CryptoCurrency.USDC && !knowsAboutUsdt.value) {
                context.root.$router.push({ name: 'usdt-added' });
                return;
            }

            setActiveCurrency(stablecoin.value);

            if (isMobile.value) {
                context.root.$router.push('/transactions');
            }
        }

        const showFullLegacyAccountNotice = computed(() =>
            isLegacyAccount.value
            && activeAccountInfo.value!.addresses.length === 1
            && !isTablet.value);

        const showModalLegacyAccountNotice = ref(false);

        function determineIfShowModalLegacyAccountNotice() {
            showModalLegacyAccountNotice.value = isLegacyAccount.value && isTablet.value;
        }

        function determineModalToShow() {
            determineIfShowModalLegacyAccountNotice();
        }

        watch(activeAccountInfo, determineModalToShow);

        const { consensus: btcConsensus } = useBtcNetworkStore();
        const { consensus: polygonConsensus } = usePolygonNetworkStore();

        const { updateAvailable } = useSettingsStore();

        // Html refs for backgrounds and swap buttons' positions
        const root$ = ref<HTMLElement | null>(null);
        const usdcAccount$ = ref<HTMLElement | null>(null);
        const nimiqAccount$ = ref<HTMLElement | null>(null);
        const bitcoinAccount$ = ref<HTMLElement | null>(null);
        const nimBtcSwapTooltip$ = ref<Tooltip | null>(null);
        const nimUsdcSwapTooltip$ = ref<Tooltip | null>(null);
        const btcUsdcSwapTooltip$ = ref<Tooltip | null>(null);

        const forceUpdateRef = ref(false);
        const resizeObserver = new ResizeObserver(forceUpdate);
        const mutationObserver = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.target instanceof Element && !mutation.target.classList.contains('tooltip')) {
                    forceUpdate();
                    break;
                }
            }
        });

        // start observing on mount / listen to window resize
        onMounted(async () => {
            resizeObserver.observe(root$.value!);
            mutationObserver.observe(root$.value!, { // for account switch & add address
                attributes: false,
                childList: true,
                subtree: true,
            });
        });
        // disconnect observers on unmount / remove window resize listener
        onUnmounted(() => {
            resizeObserver.disconnect();
            mutationObserver.disconnect();
        });
        onActivated(forceUpdate); // to update on view change (settings <-> main view)

        async function forceUpdate() {
            await context.root.$nextTick();
            // trick to force vue to update the position on component resize
            forceUpdateRef.value = !forceUpdateRef.value;
            /**
             * In some cases, the re-render after resize takes a bit too long
             * and the background svg is not positioned correctly.
             * (example: macos window maximize & minimize / toggling mobile view in devtools)
             * This is workaround to force to re-render the background svg and position it correctly.
             * This does the same as doing a setTimeout(() => forceUpdateRef.value = !forceUpdateRef.value, 0);
             */
            await context.root.$nextTick();
            forceUpdateRef.value = !forceUpdateRef.value;
        }

        const accountBgPosition = computed(() => {
            // trick to force vue to update the position on component resize
            // eslint-disable-next-line
            forceUpdateRef.value;

            const currencies = ['usdc', 'nimiq', 'bitcoin'];
            const ret = currencies.reduce((obj: Record<string, any>, currency) => {
                obj[currency] = null;
                return obj;
            }, {});

            currencies.forEach((currency) => {
                const el$ = {
                    usdc: usdcAccount$.value,
                    nimiq: nimiqAccount$.value,
                    bitcoin: bitcoinAccount$.value,
                }[currency];

                if (el$ && root$.value) {
                    const accountPosition = el$.getBoundingClientRect();
                    const accountOverviewPosition: DOMRect = root$.value.getBoundingClientRect();

                    ret[currency] = {
                        height: accountPosition.height,
                        width: accountPosition.width,
                        top: `${accountPosition.top - accountOverviewPosition.top}px`,
                        left: `${accountPosition.left - accountOverviewPosition.left}px`,
                    };
                }
            });

            return ret;
        });

        const nimAccountBgCutouts = computed(() => {
            let bottom;

            if (nimBtcSwapTooltip$.value && nimUsdcSwapTooltip$.value) {
                bottom = [nimBtcSwapTooltip$.value.isShown, nimUsdcSwapTooltip$.value.isShown];
            } else if (nimBtcSwapTooltip$.value && !nimUsdcSwapTooltip$.value) {
                bottom = (hasPolygonAddresses.value && config.polygon.enabled)
                    ? [nimBtcSwapTooltip$.value.isShown, null]
                    : [nimBtcSwapTooltip$.value.isShown];
            } else if (!nimBtcSwapTooltip$.value && nimUsdcSwapTooltip$.value) {
                bottom = (hasBitcoinAddresses.value && config.enableBitcoin)
                    ? [null, nimUsdcSwapTooltip$.value.isShown]
                    : [nimUsdcSwapTooltip$.value.isShown];
            }

            return { bottom };
        });

        let timeoutid: any;
        function onSwapButtonPointerDown(event: TouchEvent, route: string) {
            clearTimeout(timeoutid);
            router.push(`/swap/${route}`);
            timeoutid = setTimeout(() => {
                ((event.target! as HTMLElement).parentNode as HTMLElement).focus();
            }, 100);
        }

        // const { totalAccountStake } = useStakingStore();

        return {
            stablecoin,
            activeAccountInfo,
            AccountType,
            backup,
            canHaveMultipleAddresses,
            addAddress,
            activeAccountId,
            onAddressSelected,
            nimAccountBalance,
            btcAccountBalance,
            accountUsdcBridgedBalance,
            accountUsdcBalance,
            accountUsdtBridgedBalance,
            showFullLegacyAccountNotice,
            showModalLegacyAccountNotice,
            selectBitcoin,
            selectStablecoin,
            activeCurrency,
            CryptoCurrency,
            SwapAsset,
            hasBitcoinAddresses,
            hasPolygonAddresses,
            btcConsensus,
            polygonConsensus,
            updateAvailable,
            root$,
            usdcAccount$,
            nimiqAccount$,
            bitcoinAccount$,
            nimBtcSwapTooltip$,
            nimUsdcSwapTooltip$,
            btcUsdcSwapTooltip$,
            accountBgPosition,
            nimAccountBgCutouts,
            onSwapButtonPointerDown,
            isMobile,
            // totalAccountStake,
        };
    },
    components: {
        ArrowRightSmallIcon,
        AlertTriangleIcon,
        AccountBalance,
        AddressList,
        BitcoinIcon,
        UsdcIcon,
        UsdtIcon,
        UsdtIconPadded,
        MenuIcon,
        ConsensusIcon,
        MobileActionBar,
        LegacyAccountNotice,
        LegacyAccountUpgradeButton,
        LegacyAccountNoticeModal,
        // OasisLaunchModal,
        Amount,
        FiatConvertedAmount,
        AttentionDot,
        CircleSpinner,
        MiniAddIcon,
        DoubleArrowIcon,
        Tooltip,
        LinkedDoubleArrowIcon,
        AddressListBackgroundSvg,
        // StakingSummaryMobile,
        // AccountStake,
    },
});
