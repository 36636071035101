

























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    props: {
        pulsing: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        fullSize: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
});
