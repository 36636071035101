






















import { computed, defineComponent } from '@vue/composition-api';
import Amount from '../Amount.vue';
import { useStakingStore } from '../../stores/Staking';
import OneLeafStakingIcon from '../icons/Staking/OneLeafStakingIcon.vue';
import TwoLeafStakingIcon from '../icons/Staking/TwoLeafStakingIcon.vue';
import ThreeLeafStakingIcon from '../icons/Staking/ThreeLeafStakingIcon.vue';
import { useAddressStore } from '../../stores/Address';

export default defineComponent({
    setup() {
        const { activeStake: stake, restakingRewards } = useStakingStore();
        const { activeAddressInfo } = useAddressStore();

        const currentPercentage = computed(() => {
            const alreadyStakedAmount = stake.value?.activeBalance || 0;
            const deactivatedAmount = stake.value?.inactiveBalance || 0;
            const retiredAmount = stake.value?.retiredBalance || 0;
            const totalStakedAmount = alreadyStakedAmount + deactivatedAmount + retiredAmount;

            const availableAmount = (activeAddressInfo.value?.balance || 0) + totalStakedAmount;
            const percent = (100 * alreadyStakedAmount) / availableAmount;

            return percent;
        });

        return {
            stake,
            currentPercentage,
            restakingRewards,
        };
    },
    components: {
        OneLeafStakingIcon,
        TwoLeafStakingIcon,
        ThreeLeafStakingIcon,
        Amount,
    },
});
