

















































































import { defineComponent, computed } from '@vue/composition-api';
import {
    CircleSpinner,
    AlertTriangleIcon,
    CashlinkXSmallIcon,
    Identicon,
    FiatAmount,
    CrossIcon,
} from '@nimiq/vue-components';
import { SwapAsset } from '@nimiq/fastspot-api';
import { toMs, Transaction, TransactionState } from '../stores/Transactions';
import Amount from './Amount.vue';
import FiatConvertedAmount from './FiatConvertedAmount.vue';
import UnclaimedCashlinkIcon from './icons/UnclaimedCashlinkIcon.vue';
// import HistoricValueIcon from './icons/HistoricValueIcon.vue';
import BitcoinIcon from './icons/BitcoinIcon.vue';
import UsdcIcon from './icons/UsdcIcon.vue';
import UsdtIcon from './icons/UsdtIcon.vue';
import BankIcon from './icons/BankIcon.vue';
import SwapSmallIcon from './icons/SwapSmallIcon.vue';
import {
    FIAT_PRICE_UNAVAILABLE,
    CASHLINK_ADDRESS,
    BANK_ADDRESS,
    STAKING_CONTRACT_ADDRESS,
} from '../lib/Constants';
import { useTransactionInfo } from '../composables/useTransactionInfo';
import { useFormattedDate } from '../composables/useFormattedDate';
import TransactionListOasisPayoutStatus from './TransactionListOasisPayoutStatus.vue';
import HeroIcon from './icons/Staking/HeroIcon.vue';

export default defineComponent({
    props: {
        transaction: {
            type: Object as () => Transaction,
            required: true,
        },
    },
    setup(props) {
        const constants = {
            FIAT_PRICE_UNAVAILABLE,
            CASHLINK_ADDRESS,
            BANK_ADDRESS,
            STAKING_CONTRACT_ADDRESS,
        };

        const state = computed(() => props.transaction.state);

        const transaction = computed(() => props.transaction);

        const {
            data,
            isCashlink,
            isIncoming,
            isSwapProxy,
            peerAddress,
            peerLabel,
            swapData,
            swapInfo,
            fiat,
        } = useTransactionInfo(transaction);

        const timestamp = computed(() => transaction.value.timestamp && toMs(transaction.value.timestamp));
        const { dateDay, dateMonth, dateTime } = useFormattedDate(timestamp);

        return {
            constants,
            state,
            TransactionState,
            dateDay,
            dateMonth,
            dateTime,
            data,
            fiat,
            isCashlink,
            isIncoming,
            isSwapProxy,
            peerAddress,
            peerLabel,
            SwapAsset,
            swapInfo,
            swapData,
        };
    },
    components: {
        CircleSpinner,
        CrossIcon,
        AlertTriangleIcon,
        Amount,
        FiatConvertedAmount,
        CashlinkXSmallIcon,
        Identicon,
        FiatAmount,
        UnclaimedCashlinkIcon,
        // HistoricValueIcon,
        BitcoinIcon,
        UsdcIcon,
        UsdtIcon,
        BankIcon,
        SwapSmallIcon,
        TransactionListOasisPayoutStatus,
        HeroIcon,
    },
});
